
#login {
    font-family: 'Poppins', sans-serif;
    // background: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(172, 216, 255, 255));
    background-size: cover;


}



#login .border_total {
    border: 1px solid #ccc;
    background: #fff;
    padding: 30px 10px;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 50px;
    width: 30%;

}

#login .bodercard {
    border: none;
    background: #fff;
    padding: 30px 35px;
    margin-left: 15px;
    margin-top: 60px;
    margin-bottom: 50px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 22vw;
    
}

#login .login_btn {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: #3B77D7;
    color: #fff;
    margin-top: 20px;

}

#login label {
    font-size: 13px;
    font-weight: 600;
}

.login-title {
    color: #2E4DD1;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    width: 750px;
}

.p-carousel-next,
.p-carousel-prev {
    display: none !important;
}

.left-section .right-section {
    margin: 0px;
    padding: 0px;
}

.login-header-title {
    color: #000000;
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 15px;

}

.login-header-desc {

    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #757575;
    margin-bottom: 30px;
}

.login-label {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.carousel-title {
    width: 80%;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;



}

.carousel-desc {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

}

.product-item {

    margin: auto;
}

.carousel-image {
    border-radius: 18px;
    height: 200px;
    width: 50vh;
    background-size: cover;
}

.input-1 {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #757575;
    border-radius: 25px;
    box-sizing: border-box;
    background-color: transparent;
}


.img-fluid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 50px;
}

.dashboard-card1 {
    // width: 200px;
    // height: 110px;
    border-radius: 15px;
    margin: 5px 10px 5px 10px;

}

.dashboard-card1 .card-title1 {
    text-align: center;
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    color: #fff;


}

.product-image1 {

    text-align: right;
}

.dashboard-card1 .card-value1 {
    font-size: 29px;
    color: #fff;
}